import { memo } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 1.4rem;
  letter-spacing: -0.015em;
  line-height: 1.6;
  margin: 0 auto;
  max-width: ${(props) => props.theme.maxContentWidth};

  @media ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 2rem;
  }

  img {
    margin: 0 auto;
  }
`;

const FirstLetter = styled.span`
  color: var(--theme-color-title);
  display: block;
  float: left;
  font-size: 5rem;
  font-weight: 800;
  line-height: 0.85;
  margin-right: 0.5rem;
  padding: 0.2rem 0.5rem 0 0;
  position: relative;

  @media ${(props) => props.theme.breakpoints.sm} {
    font-size: 7.5rem;
  }

  &:before {
    background: var(--theme-color-section);
    box-shadow: 4px 4px 0 0 var(--theme-color-section);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: skew(-5deg) rotate(-5deg) translateX(-5px);
    width: 100%;
    z-index: -1;
  }
`;

type DescriptionProps = {
  content: string;
  leadingLetter?: boolean;
};

const Description = ({ content, leadingLetter }: DescriptionProps) => {
  if (!content) {
    return null;
  }

  const Paragraph = ({ children, node }) => {
    const firstChild = node.children[0];

    if (firstChild.type === 'text') {
      const firstLine = firstChild.value;
      const firstWord = firstLine?.substr(0, firstLine.indexOf(' '));
      if (
        firstWord &&
        firstChild.position.start.line === 1 &&
        firstChild.position.start.column === 1
      ) {
        return (
          <p>
            <FirstLetter aria-hidden={true}>
              {firstWord.substr(0, 1)}
            </FirstLetter>
            <span aria-hidden={true}>{firstWord.substr(1)}</span>
            <span className="visually-hidden">{firstWord}</span>
            {firstLine.substr(firstLine.indexOf(' '))}
          </p>
        );
      }
    }
    return <p>{children}</p>;
  };

  return (
    <Wrapper>
      {leadingLetter ? (
        <>
          <ReactMarkdown
            components={{
              p: Paragraph,
            }}
          >
            {content}
          </ReactMarkdown>
        </>
      ) : (
        <ReactMarkdown>{content}</ReactMarkdown>
      )}
    </Wrapper>
  );
};

export default memo(Description);
